.xmas2017--poster {
    @include mq($from: tablet) {
        width: 660px;
        margin: 0 auto;
    }

    .xmas2017--header {
        position: relative;
        transform: none;
        left: 0;
        width: auto;

        @include mq($from: tablet) {
            border-radius: 0;
        }

        h1 {
            margin: 0;
        }
    }
}

.xmas2017--share {
    //background: $purple;
    background: url(../img/share-image-bg.png) center no-repeat;
    background-size: cover;
    height: 700px;
    color: #FFF;
    padding: 16px 15px 13px;
    text-align: center;

    h1 {
        display: block;
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
        margin: 0 0 13px;
    }

    &-facebook, &-twitter, &-whatsapp {
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: bottom;
        margin: 0 0 0 22px;
        padding: 0;
        background: transparent;

        svg {
            width: 30px;
            height: 30px;

            path {
                fill: #FFF;
                transition: fill .15s ease-in-out;
            }
        }

        &:hover, &:focus {
            svg path {
                fill: #FFF;
            }
        }
    }

    &-facebook {
        margin-left: 0;
    }

    &-whatsapp {
      width: 30px;
      height: 30px;
      background: url(../img/whatsapp.svg) center no-repeat;
    }

    .xmas2017--poster-poster {
        margin-top: 30px;
        img {
            display: block;
            width: 500px;
            height: auto;
            margin: 0 auto;
            max-height: 500px; // 500x500px
            -ms-interpolation-mode: bicubic;
        }
    }

}

.xmas2017--poster-addall {
    display: block;
    width: 100%;
    background: $brownDark;
    font: 600 16px/1 $font-bbc-regular;
    padding: 20px 0;
    text-decoration: none;

    @include mq($from: tablet) {
        width: 600px;
        margin: 0 auto;

        .xmas2017--poster-calendar-all {
            width: 480px;
            margin: 0 auto;
            padding: 22px 20px;
            background-color: $greyLight;
            position: relative;
            color: $brown;
            border-radius: 10px;

            &::after {
              content: '';
              display: block;
              position: absolute;
              right: 16px;
              top: 12px;
              width: 40px;
              height: 40px;
              background: url(../img/calendar-brown.svg) center no-repeat;
            }
        }
    }

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.xmas2017--poster-shows {
   border-top: 1px solid #333;
   border-bottom: 1px solid $brownDark;
   background: $brownDark;

   @include mq($from: tablet) {
       width: 600px;
       margin: 0 auto !important;
   }

   .xmas2017--show {
       padding: 12px 50px 0 75px;

       @include mq($from: tablet) {
           padding-right: 210px;
       }

       h3 {
           font-weight: 600;
       }

       time {
           opacity: .7;
       }

   }
}
