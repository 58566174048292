.xmas2017--pickshows {
    padding: 125px 0 0;
    position: relative;
    margin: 0 0 66px; // 66px = .xmas2017--progress height
    background-color: $purple;

    @include mq($from: tablet) {
        padding-top: 160px;
        width: 600px;
        margin: 0 auto 66px;

        .xmas2017--header {
            border-radius: 0;
        }

        .xmas2017-celebrities-wrapper {
            position: fixed;
            padding: 20px 0;
            left: 0;
            top: 75px;
            width: 100%;
            text-align: center;
            background-color: $purple;
            z-index: 4;

            @include mq($from: tablet) {
                width: 620px;
                left: 50%;
                transform: translateX(-50%);
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }

            .xmas2017-celebrity-list {
                display:inline;
                padding: 10px 0 20px 0;
                margin:0;

                .xmas2017--celebrity {
                    width: 70px;
                    height: 70px;
                    margin: 0 20px;
                    padding: 0;
                    background: transparent;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    nav {
        position: fixed;
        top: 185px;
        left: 0;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        z-index: 4;

        @include mq($from: tablet) {
            width: 620px;
            left: 50%;
            transform: translateX(-50%);
        }

        button {
            flex: 0 0 50%;
            padding: 11px 0;
            margin: 0;
            border: none;
            border-radius: 0;
            border-top: 1px solid rgba(255, 255, 255, .1);
            background: $brownDark;
            font: 400 16px/1.0 $font-bbc-regular;
            color: $greyLight;

            &.xmas2017--on {
                font-weight: 600;
                background: $greyLight;
                color: $brown;
            }

            &:hover, &:focus {
                text-decoration: underline;
            }

            @include mq($from: tablet) {
                &:first-child {
                    border-bottom-left-radius: 6px;
                }

                &:last-child {
                    border-bottom-right-radius: 6px;
                }
            }
        }

        @include mq($from: tablet) {
            &::after {
                content: '';
                height: 10px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: -10px;
                background: linear-gradient(to bottom, rgba(0, 0, 0, .34), rgba(0, 0, 0, 0));
                z-index: 1;
            }
        }
    }

    &-list {
        @include mq($from: tablet) {
            text-align: center;
            font-size: 0;

            li {
                display: inline-block;
            }
        }
    }

    .xmas2017--show {
        padding-left: 75px;
        transition: background-color .15s ease-in-out, color .15s ease-in-out;

        &::after {
            content: '';
            display: block;
            width: 60px;
            height: 60px;
            background: rgba(153, 46, 46, 0.8)
                url(../img/tick.svg) center/26px 21px no-repeat;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            transition: opacity .15s ease-in-out;
            z-index: 2;
        }

        &.xmas2017--show-selected {
            background: $grey;

            h3 {
                font-weight: 600;
                z-index: 3;
                color: $greyLight;
            }

            &:hover, &:focus {
                text-decoration: underline;
            }

            &::after {
                opacity: 1;
            }
        }

        &:hover, &:focus {
            background: $grey;
            box-shadow: none;
            outline: none;
            color: $brown;
        }

        @include mq($from: tablet) {
            padding: 0;
            width: 200px;
            height: 200px;
            margin: 0;
            position: relative;

            &::after {
                width: 200px;
                height: 200px;
                background: rgba(153, 46, 46, 0.8)
                    url(../img/tick.svg) center/50px 40px no-repeat;
            }

            &::before {
                content: '';
                width: 100%;
                height: 50%;
                position: absolute;
                left: 0;
                bottom: 0;
                background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                z-index: 1;
            }

            h3 {
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: absolute;
                left: 15px;
                bottom: 15px;
                font: 600 16px/1.4 $font-body;
                text-align: left;
                z-index: 1;
            }

            img {
                width: 100%;
                height: auto;
                max-width: 100%;
            }

            &.xmas2017--show-selected {
                &:hover, &:focus {
                    h3 {
                        text-decoration: underline;
                    }
                }

                h3 {
                    color: $greyLight;
                }
            }
        }
    }

    .xmas2017--listseparator {
        background: $grey;
        color: #FFF;
        padding: 5px 15px;
        text-transform: uppercase;
        margin: 0 0 1px;

        @include mq($from: tablet) {
            padding: 0;
            display: inline-flex;
            flex-flow: column nowrap;
            justify-content: center;
            width: 200px;
            height: 200px;
            vertical-align: top;
            margin: 0;
            text-align: center;
            font: 300 64px/1.0 $font-bbc-regular;
            background: $black;

            span {
                flex: 0 0 auto;
            }
        }
    }

    .xmas2017--proceed {
        padding-top: 19px;
    }

    .xmas2017--count {
        display: inline-block;
        line-height: 1;
        color: $brown;
        margin: -3px 0 0;

        b {
            display: inline-block;
            vertical-align: 0;
            background: $brown;
            text-align: center;
            font-weight: normal;
            width: 27px;
            height: 27px;
            box-sizing: border-box;
            border: none;
            border-radius: 50%;
            color: $greyLight;
            padding: 5px 0 0;
        }
    }
}
