$width-mobile: 320px;
$width-tablet: 768px;
$width-desktop: 1000px;

$mq-breakpoints: (
    mobile: $width-mobile,
    tablet: $width-tablet,
    desktop: $width-desktop
);

@font-face {
    font-family: 'BBC Reith Sans Reg';
    src: url('../fonts/BBCReithSans_Rg.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BBC Reith Sans Bold';
    src: url('../fonts/BBCReithSans_Bd.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

$font-title: 'BBC Reith Sans Bold', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$font-body: 'Helvetica Neue', Arial, Helvetica, sans-serif;
$font-bbc-regular: 'BBC Reith Sans Reg', 'Helvetica Neue', Arial, Helvetica, sans-serif;

$black: #000000;
$grey: #424242;
$greyDark: #222222;
$greyLight: #D9D9D9;

$purple: #6C1B3D;
$brownDark: #26111A;
$brown: #641B39;
$copper: #992E2E;
$festiveRed: #9D2836;

@import 'mq';

html {
    height: 100%;
    overflow-x: hidden;
}

body {
    padding: 0;
    margin: 0;
    border: none;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    background: #000;
    color: #FFF;
    border-radius: 0;
}

noscript {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font: 24px/1.0 $font-bbc-regular;
}

.xmas2017 {
    font: 14px/1.3 $font-bbc-regular;
    padding: 50px;
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;

    @include mq($from: tablet) {
        background-image: url(../img/xmas-bg.jpg);
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    button {
        font-family: $font-bbc-regular;
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    a:focus {
        outline: none;
    }

    .xmas2017--header {
        position: fixed;
        padding: 20px 0;
        left: 0;
        top: 0;
        width: 100%;
        text-align: center;
        background-color: $purple;
        z-index: 4;

        @include mq($from: tablet) {
            width: 620px;
            left: 50%;
            transform: translateX(-50%);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        h1 {
            font-size: 20px;
            line-height: 1.2;
            margin: 0 0 6px;
            color: $greyLight;
            transition: color .15s ease-in-out;
        }

        h2 {
            font-size: 14px;
            line-height: 1.15;
            font-weight: 400;
        }

        .xmas2017--header-msg {
            background: $brownDark;
            padding: 14px 0;
            margin: 25px 0 0;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: none;

            @include mq($from: tablet) {
                display: block;
            }
        }

        .xmas2017--back {
            position: absolute;
            left: 0;
            top: 0;
            width: 33px;
            height: 100%;
            background: url(../img/chevron-left.svg) 15px 20px/18px 22px no-repeat;
            font-size: 0;
            color: rgba(0, 0, 0, 0);
            opacity: .5;
            transition: opacity .15s ease-in-out;

            &:hover, &:focus {
                opacity: 1;
            }
        }
    }

    .xmas2017--show {
        position: relative;
        padding: 4px 22px 0 0;
        min-height: 60px;
        width: 100%;
        text-align: left;
        box-sizing: border-box;
        background: $brown;
        margin: 0 0 1px;
        color: #FFF;

        h3 {
            font-weight: 400;
            font-size: 16px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            max-width: 60px;
            height: auto;
        }
    }

    .xmas2017--progress {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 4;

        @include mq($from: tablet) {
            width: 620px;
            left: 50%;
            transform: translateX(-50%);
        }

        &:disabled {
            cursor: default;
        }

        &-bar {
            display: block;
            width: 100%;
            background: $brownDark;

            @include mq($from: tablet) {
                width: 600px;
                margin: 0 0 0 10px;
            }

            span {
                display: block;
                background: $brown;
                height: 3px;
                transition: width .35s ease-in-out;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    border: none;
                    background: $brown;
                    position: absolute;
                    right: -4px;
                    top: -2px;
                    box-shadow: 0 0 4px rgba(255, 255, 255, .8);
                    z-index: 3;
                }
            }
        }
    }

    .xmas2017--proceed {
        width: 100%;
        text-align: left;
        background-color: $greyLight;
        font-size: 18px;
        padding: 20px 15px;
        margin: 0;
        transition: color .15s ease-in-out, background-position .35s ease-in-out;

        @include mq($from: tablet) {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &::before {
                content: '';
                height: 10px;
                width: 100%;
                position: absolute;
                left: 0;
                top: -10px;
                background: linear-gradient(to top, rgba(0, 0, 0, .12), rgba(0, 0, 0, 0));
                z-index: 1;
            }
        }

        span {
            float: right;
            font-weight: 600;

            &::after {
                content: '';
                display: inline-block;
                width: 18px;
                height: 22px;
                background: url(../img/chevron-right.svg) center/18px 22px no-repeat;
                vertical-align: -5px;
                margin: 0 0 0 10px;
                transition: opacity .15s ease-in-out;
            }
        }

        &:hover, &:focus {
            background-position: 0 0, 0 -20px;
            text-decoration: underline;

            span {
                text-decoration: underline;
            }
        }

        &:disabled {
            color: rgba(0, 0, 0, .3);
            background-position: 0 0, 0 0;
            text-decoration: none;

            span::after {
                opacity: .3;
            }
        }
    }

    .xmas2017--vh {
        border: 0;
        clip: rect(0 0 0 0);
        height: 0;
        margin: -2px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 0;
    }
}

@include mq($until: tablet) {
    body.xmas2017--landscape {
        overflow: hidden;
    }

    .xmas2017--rotatemessage {
        background: url(../img/rotate.svg) center 42%/96px 90px no-repeat rgba(0, 0, 0, .8);
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: none;
        z-index: 10;

        body.xmas2017--landscape & {
            display: block;
        }

        &::after {
            content: 'Please rotate your device';
            font: 20px/1 $font-bbc-regular;
            color: #FFF;
            position: absolute;
            left: 50%;
            top: 42%;
            transform: translate(-50%, -50%);
            margin: 78px 0 0;
            z-index: 10;
        }
    }
}

@import 'welcome';
@import 'pickshows';
@import 'poster';
@import '404';
