.xmas2017--404 {
    font: 14px/1.3 $font-bbc-regular;
    text-align: center;
    padding: 0 30px;
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: url(../img/page-bg-bottom.jpg) center bottom/100% auto no-repeat fixed,
        $black url(../img/page-bg-top.jpg) center top/100% auto no-repeat fixed;

    h1 {
        color: #FFF;
        font-size: 144px;
        line-height: 1;
        margin: 0 0 20px;
        padding: 0;
    }

    h2 {
        color: $brown;
        font-size: 20px;
        line-height: 1;
        margin: 0 0 20px;
        padding: 0;
    }

    p {
        margin: 0 0 20px;
    }

    a {
        color: $brown;
        text-decoration: underline;
    }
}
