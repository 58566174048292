.xmas2017--welcome {
    padding: 1px;
    text-align: center;
    flex: 1 0 auto;

    @include mq($from: tablet) {
        max-width: 520px;
        width: 100%;
        margin: auto auto;
        max-height: 90vh;
        background: url(../img/welcome-background-gradiant.png);
        padding: 0 20px;
    }

    h1 {
        padding: 5vh 0;

        @include mq($from: tablet) {
            padding: 5vh 5vh 0;
        }

        img {
            width: 12vw;

            &.xmas2017-bbc-logo {
                width: 10vw;
            }
        }
    }

    h2 {
        color: $greyLight;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2;
        margin: 0 0 11px;
    }

    p {
        padding: 10px 30px;
        margin: 2vh 0;
        font: 400 18px $font-bbc-regular;

        &.xmas2017-middle-text {
            line-height: 1.5;
        }

        &.xmas2017-top-text {
            font-size: 20px;
            line-height: 1.2;
        }
    }

    ol {
        list-style: none;
        padding: 0 15px;
        margin: 0 0 20px;

        li {
            margin-bottom: 4px;
        }

        @include mq($from: tablet) {
            margin: 0 0 30px;
            font-size: 16px;
            line-height: 1.35;
        }
    }

    &-proceed {
        color: $festiveRed;
        font: 600 18px/1.2 $font-title;
        padding: 18px 32px;
        border: none;
        border-radius: 10px;
        transition: color .15s ease-in-out, background-position .35s ease-in-out;

        @include mq($from: tablet) {
            margin: 10px 0 0;
            padding: 18px 52px;
        }

        &:hover, &:focus {
            background-position: 0 0, 0 -20px;
            text-decoration: underline;
        }

        &:disabled {
            color: rgba(0, 0, 0, .3);
            cursor: default;
            background-position: 0 0, 0 0;
            text-decoration: none;
        }
    }
}
